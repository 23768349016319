<template>
  <section class="section">
    <form>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label for="model">
              {{ $t("hardware_model") }}
              <ToolTip :title="$parent.$t('hints.model')" />
            </label>
            <select class="form-control" data-testid="type" v-model="type_id">
              <option
                v-for="(item, ix) in types"
                v-bind:key="ix"
                v-bind:value="item.id"
                v-bind:title="item.description"
                >{{ item.name }}</option
              >
            </select>
          </div>
        </div>
        <div class="col-md-4" v-if="protocols && protocols.length">
          <div class="form-group">
            <label for="model"
              >{{ $t("protocol") }}
              <ToolTip :title="$parent.$t('hints.protocol')" />
            </label>
            <select
              class="form-control"
              data-testid="protocol"
              v-model="protocol_id"
            >
              <option
                v-for="(item, ix) in protocols"
                v-bind:key="ix"
                v-bind:value="item.id"
                v-bind:title="item.description"
                >{{ item.name }}</option
              >
            </select>
          </div>
        </div>
        <div class="col-md-4" v-if="isMQTT">
          <div class="form-group" v-if="connector.mqtt_topic_prefix">
            <label
              >{{ $t("identifier") }} ({{ $t(`topic`) }})
              <ToolTip :title="$parent.$t('hints.identifier')" />
            </label>
            <div class="input-group">
              <input
                type="text"
                class="form-control text-center"
                disabled
                :value="connector.mqtt_topic_prefix"
              />
              <div
                class="input-group-addon btn"
                v-bind:title="$t('copy', { item: $t('topic') })"
                v-on:click.prevent.stop="
                  $utils.toClipboard(connector.mqtt_topic_prefix)
                "
              >
                <i class="fa fa-copy"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-else>
          <div class="form-group" v-if="type">
            <label>{{ $t("options") }}</label>
            <div class="checkbox-group">
              <label class="checkbox-inline" v-if="type.allow_datetime_update">
                <input
                  type="checkbox"
                  v-model="datetime_update"
                  data-testid="datetime-update"
                  v-bind:disabled="modelType ? true : false"
                />
                {{ $t("datetime_update") }}
                <ToolTip :title="$parent.$t('hints.datetime_update')" />
              </label>
              <label class="checkbox-inline">
                <input
                  type="checkbox"
                  v-model="optmized"
                  data-testid="optimized"
                  v-bind:disabled="modelType ? true : false"
                />
                {{ $t("optimized") }}
                <ToolTip :title="$parent.$t('hints.optimized')" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
    <template v-if="protocol">
      <FormEquipmentMQTT
        v-if="isMQTT"
        :protocol="protocol"
        :connector="connector"
      />
      <FormEquipmentCLP v-else :protocol="protocol" :connector="connector" />
    </template>
  </section>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
import FormEquipmentMQTT from "@/components/registration/form-equipment-mqtt.vue";
import FormEquipmentCLP from "@/components/registration/form-equipment-clp.vue";

export default {
  name: "FormEquipmentType",
  components: {
    ToolTip,
    FormEquipmentMQTT,
    FormEquipmentCLP
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    connectorModel: {
      type: Object,
      required: false,
      default: () => null
    },
    connector: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      type_id: "",
      protocol_id: "",
      datetime_update: false,
      optmized: false
    };
  },
  computed: {
    isMQTT() {
      return this?.protocol?.is_mqtt_protocol || false;
    },
    entry() {
      let self = this;
      if (self.type_id && self.protocol_id) {
        return {
          type_id: self.type_id,
          protocol_id: self.protocol_id,
          datetime_update: self.datetime_update,
          optmized: self.optmized
        };
      }
      return null;
    },
    type() {
      let self = this;
      let lst = this.types.filter(function(i) {
        return i.id == self.type_id;
      });
      return (lst.length && lst[0]) || null;
    },
    allTypes() {
      return (
        (this.$root.config &&
          this.$root.config.references &&
          this.$root.config.references.connector_types) ||
        []
      );
    },
    modelProtocol() {
      let protocol = null;
      if (
        this?.modelProtocols?.length &&
        this?.connectorModel?.model?.protocol?.id
      ) {
        protocol = this.modelProtocols.find(
          ({ id }) => id == this.connectorModel.model.protocol.id
        );
        if (protocol) {
          protocol = JSON.parse(JSON.stringify(protocol)); // modify copy only
          let typeAttributeList = [
            "is_modbus_protocol",
            "is_mqtt_protocol",
            "is_scphi_protocol"
          ];
          for (var i in typeAttributeList) {
            let typeAttr = typeAttributeList[i];
            if (protocol[typeAttr]) {
              protocol["typeAttr"] = typeAttr;
              break;
            }
          }
        }
      }
      return protocol;
    },
    modelType() {
      let self = this;
      if (
        self.connectorModel &&
        self.connectorModel.model &&
        self.connectorModel.model.connector_type
      ) {
        return self.allTypes.find(
          (i) => self.connectorModel.model.connector_type.id == i.id
        );
      }
      return null;
    },
    modelProtocols() {
      let self = this;
      let protocols = null;
      if (self.modelType) {
        let modelType = this.modelType;
        if (
          modelType &&
          modelType.allowed_protocols &&
          modelType.allowed_protocols.length
        ) {
          protocols = modelType.allowed_protocols;
        }
      }
      return protocols || [];
    },
    types() {
      let lst = null;

      //
      // DEPRECIATED
      // it filters the hardware list, leaving the compatible ones based on its model
      //
      /*
      if (self.modelProtocols.length) {
        let self = this;
        let protocols = self.modelProtocols;
        console.log(self.modelProtocols);
        lst = this.allTypes.filter((i) => {
          let hasValidProtocol = false;
          for (var ix in protocols) {
            if (i.allowed_protocols.some((p) => protocols[ix].id == p.id)) {
              hasValidProtocol = true;
              break;
            }
          }
          return hasValidProtocol;
        });
      }
      */

      // starting on 2022, new protocols were added and their ids are not in the list of the base model allowed protocols
      if (this.modelProtocol) {
        lst = this.allTypes.filter((hardwareType) => {
          return (hardwareType?.allowed_protocols || []).some(
            (p) => p[this.modelProtocol.typeAttr]
          );
        });
      }
      return lst || this.allTypes;
    },
    protocols() {
      // DEPRECIATED - SEE types()
      // let self = this;
      // return (self.type && self.type.allowed_protocols) || [];
      if (!this.type) return [];
      if (this.modelProtocol) {
        return (this.type.allowed_protocols || []).filter(
          (p) => p[this.modelProtocol.typeAttr]
        );
      }
      return this.type.allowed_protocols;
    },
    protocol() {
      let self = this;
      let lst =
        self.protocol_id &&
        self.protocols &&
        self.protocols.filter(function(i) {
          return i.id == self.protocol_id;
        });
      return (lst && lst.length && lst[0]) || null;
    },
    topic: {
      set(value) {},
      get() {
        return "connector";
      }
    }
  },
  watch: {
    entry: {
      handler(n, o) {
        if (n) {
          this.$emit("input", n);
        }
      },
      deep: true
    },
    type_id: {
      handler(n, o) {
        let self = this;
        if (n) {
          if (self.protocols.length) {
            if (self.protocols.length == 1) {
              self.protocol_id = self.protocols[0].id;
            } else {
              if (self.protocol_id) {
                if (
                  !self.protocols.filter(function(i) {
                    return i.id == self.protocol_id;
                  }).length
                ) {
                  self.protocol_id = "";
                }
              } else {
                self.protocol_id = self.protocols[0].id;
              }
            }
          } else {
            self.protocol_id = "";
          }
          if (!self?.type?.allow_datetime_update) {
            self.datetime_update = false;
          }
        } else {
          self.protocol_id = "";
        }
        self.$emit("input", {
          type_id: self.type_id,
          protocol_id: self.protocol_id,
          datetime_update: self.datetime_update,
          optmized: self.optmized
        });
      },
      deep: true
    },
    value: {
      handler(n) {
        if (n) {
          for (var p in n || {}) {
            if (this[p] != n[n]) {
              this[p] = n[p];
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
.section {
  background-color: whitesmoke;
  border: 1px solid lightgray;
  /* border-left-width: 0;
  border-right-width: 0; */
  padding: 20px 10px;
  margin: 0 0 20px 0;
  border-radius: 5px;
}

.checkbox-group {
  white-space: nowrap;
  font-weight: bold;
  margin-top: 5px;
}
.checkbox-inline {
  margin-right: 10px;
}

.form-control[disabled] {
  background-color: white;
  opacity: 0.9;
  font-weight: bold;
}
.h4 {
  color: #676767;
}
</style>
