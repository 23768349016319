<template>
  <div v-if="protocol">
    <div
      style="position:relative;min-height:36px;background-color: transparent;"
    >
      <div class="hidden-xs action-bar text-left">
        <span class="hidden-sm">{{ $t("ip_network_configuration") }}</span>
        <span class="hidden-xs"> [broker: </span>{{ protocol.access_host }}
        <span class="hidden-xs"> ]</span>
      </div>
      <div class="action-bar text-right">
        <div
          class="hidden-sm hidden-md hidden-lg"
          style="margin-top:-12px;width:100%;margin-bottom:2px;"
        >
          broker {{ protocol.access_host }}
        </div>
        <div style="white-space: nowrap">
          <span
            class="btn btn-default btn-xs"
            @click.stop.prevent="onCertificateDownload()"
            :title="`${$t('download')} ${$t('certificate')}`"
          >
            <b>
              <i class="fa fa-download"></i>
              {{ $t("certificate") }}
            </b>
          </span>
          <span
            v-if="!connector.base_model"
            class="btn btn-default btn-xs"
            @click.stop.prevent="onSimulator($event)"
            :title="`${$t('open')} ${$t('simulator')}`"
          >
            <b>
              <i class="glyphicon glyphicon-transfer"></i>
              {{ $t("simulator") }}
            </b>
          </span>
          <span
            v-if="!connector.base_model"
            class="btn btn-default btn-xs"
            @click.stop.prevent="onEventViewer"
            :title="`${$t('open')} ${$t('synoptic.panels.event_viewer')}`"
          >
            <b>
              <i class="fa fa-bug"></i>
              <span class="hidden-md hidden-lg">
                {{ $t("events") }}
              </span>
              <span class="hidden-xs hidden-sm">
                {{ $t("synoptic.panels.event_viewer") }}
              </span>
            </b>
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <FormCopyInput
        class="col-md-6 col-sm-4"
        :label="$t('MQTT')"
        :value="protocol.access_url"
        disabled
      />
      <FormCopyInput
        class="col-md-3 col-sm-4"
        :label="$t('port')"
        :value="protocol.connector_access_port"
        :hint="val('hint_connector_access_port')"
        disabled
      />
      <FormCopyInput
        class="col-md-3 col-sm-4"
        :label="$t('secure_port')"
        :value="protocol.connector_access_secure_port"
        :hint="val('hint_connector_access_secure_port')"
        disabled
      />
    </div>
    <div class="row">
      <FormCopyInput
        class="col-md-6 col-sm-4"
        :label="$t('WebSocket')"
        :value="protocol.web_socket_url"
        disabled
      />
      <FormCopyInput
        class="col-md-3 col-sm-4"
        :label="$t('port')"
        :value="protocol.web_socket_port"
        :hint="val('hint_web_socket_port')"
        disabled
      />
      <FormCopyInput
        class="col-md-3 col-sm-4"
        :label="$t('secure_port')"
        :value="protocol.web_socket_secure_port"
        :hint="val('hint_web_socket_secure_port')"
        disabled
      />
    </div>
    <CustomActionManager v-bind:connector="connector" />
  </div>
</template>

<script>
import RealTimeEventViewer from "@/components/realtime-event-viewer.vue";
import CustomActionManager from "@/components/custom-action-manager.vue";
import FormCopyInput from "@/components/registration/form-copy-input.vue";
export default {
  name: "FormEquipmentMQTT",
  props: {
    protocol: {
      type: Object,
      required: false,
      default: () => null
    },
    connector: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  components: {
    CustomActionManager,
    FormCopyInput
  },
  methods: {
    onEventViewer() {
      this.$root.$emit("controlSidebar:setContent", RealTimeEventViewer, {
        toggle: true,
        showToggle: false,
        clickAway: {
          allowed: [".inner-panel", ".tab-content", ".box"]
        }
      });
      this.$nextTick(() => {
        this.$root.$emit("setConnector", this.connector);
      });
    },
    onSimulator($event) {
      const prop_xy = [0.6, 0.6];
      let action = {
        type: $event.ctrlKey ? "page:tab" : "page:window",
        title: "page_window",
        options: {
          url: {
            required: true,
            placeholder: "URL",
            label: "url",
            value: "/static/common/pages/mqtt-sim.html",
            index: 0
          },
          auth: {
            required: true,
            label: "authenticated",
            placeholder: "authenticated",
            value: true,
            index: 1
          },
          params: $event.shiftKey
            ? {}
            : {
                left: parseInt(
                  (window.outerWidth - window.outerWidth * prop_xy[0]) / 2
                ),
                top: parseInt(
                  (window.outerHeight - window.outerHeight * prop_xy[1]) / 2
                ),
                width: parseInt(window.outerWidth * prop_xy[0]),
                height: parseInt(window.outerHeight * prop_xy[1])
              }
        }
      };
      this.$root.$emit("custom_action", {
        source: null,
        event: "click",
        connector: this.connector,
        action: action
      });
    },
    onCertificateDownload() {
      this.$utils.download(this.$root.config.mqtt.cert, "ca.pem");
    },
    val(attr, dft) {
      return (this?.protocol || {})[attr] || dft || "";
    }
  }
};
</script>

<style scoped>
.form-control[disabled] {
  background-color: white;
  opacity: 0.9;
  font-weight: bold;
}
.action-bar {
  position: absolute;
  width: 100%;
}
.action-bar > span.btn {
  margin: 0 5px;
  padding: 6px;
  border: 0;
}

.action-bar > span.btn:hover {
  color: rgb(68 151 217);
}
</style>
