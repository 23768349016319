<template>
  <div class="modal fade" tabindex="-1" role="dialog" ref="self">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            QRCode
          </h4>
        </div>
        <div class="modal-body">
          <div class="body-content">
            <QRCode
              ref="qrcode"
              :content="content"
              :filename="iFilename"
              @state="isReady = $event == 'ready'"
            />
            <div class="info" v-if="isReady">
              <slot name="contentAction" :content="content">
                <a :href="content" target="_blank">
                  <i class="fa fa-external-link"></i>
                  <span style="margin-left:5px;">{{ content }}</span>
                </a>
              </slot>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <span class="btn btn-default pull-left" data-dismiss="modal">
            {{ $t("cancel") }}
          </span>
          <div class="pull-right" style="display:inline-block;width:250px;">
            <div class="form-group" style="position:relative">
              <div class="filename-input">
                {{ $t("filename") }}
              </div>
              <div class="input-group" v-if="isReady">
                <input
                  type="text"
                  class="form-control text-center"
                  placeholder="filename"
                  v-model="iFilename"
                />
                <div
                  class="input-group-addon btn"
                  @click.stop.prevent="$refs.qrcode.download()"
                  :title="`${$t('download')}: ${iFilename}`"
                >
                  <i class="fa fa-download"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "@/components/widgets/qrcode.vue";
export default {
  name: "QRCodeViewer",
  props: {
    content: {
      type: String,
      default: "https://hitecnologia.com.br",
      required: false
    },
    filename: {
      type: String,
      default: "",
      required: false
    }
  },
  components: {
    QRCode
  },
  data: function() {
    return {
      isReady: false,
      iFilename: ""
    };
  },
  methods: {
    onCancel() {
      this.close();
    },
    close() {
      this.$emit("close");
      $(this.$refs.self).modal("hide");
    },
    open() {
      $(this.$refs.self).modal("show");
    }
  },
  mounted() {
    let self = this;
    self.iFilename = this.filename;
    $(this.$refs.self)
      .on("shown.bs.modal", () => {
        self.$emit("show");
      })
      .on("hidden.bs.modal", () => {
        self.$emit("close");
      });
    self.open();
  }
};
</script>

<style>
.modal-body {
  text-align: center;
}

.body-content {
  margin: 0px;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  padding: 50px;
}

.info {
  margin: 15px 0 0 0;
  border-radius: 3px;
  padding: 4px;
  background: white;
  font-size: 90%;
  white-space: nowrap;
}

.filename-input {
  position: absolute;
  left: 5px;
  top: -0.8em;
  z-index: 10;
  background-color: #ffffff;
  padding: 0px 4px;
  font-weight: 600;
  border-radius: 3px;
  color: #666;
}
</style>
